import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-structural-auditing',
  templateUrl: './structural-auditing.component.html',
  styleUrls: ['./structural-auditing.component.scss']
})
export class StructuralAuditingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
