


<div id="content" role="main">
    <article>
        <section class="section pmc-content">
            <div class="background-media"> </div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn"
                        data-os-animation-delay="0s">
                        <h1 class="text-center  element-top-80 element-bottom-20 text-light" data-os-animation="none"
                            data-os-animation-delay="0s">Design & Detail Engineering</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20"
                            data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="service-title  head-title text-left element-top-60 os-animation normal regular" data-os-animation="fadeInRight"
                            data-os-animation-delay="0.1s">Design and Detail Engineering</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation"
                            data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight"
                            data-os-animation-delay="0.3s">
                            <p class="text-content">To give a win-win condition for all while recollecting our client's necessities and the troubles they defy in the present centered circumstance. We are controlled by exceptionally qualified and master experts having inside and out area information, immense industry encounter and with novel innovation that attention on the profound comprehension of the necessities and procedures to create 
                                and send imaginative web answers for our customers in their separate business range.</p>
                            <p class="text-content">We place stock in broadening the advancement of our clients with unrivaled site change and imaginative blueprints.
                            </p>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft"
                            data-os-animation-delay="0s">
                            <img alt="p11" src="../../assets/images/Designdetailengg..jpeg">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="list-container row element-top-0 element-bottom-50 os-animation"
                        data-os-animation="fadeInUp" data-os-animation-delay="0.2s">

                        <div class="col-md-6 text-normal element-top-0 element-bottom-20 text-center os-animation "
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <img class="element-top-60" alt="p11" src="../../assets/images/Design and detail engg.jpeg">
                        </div>

                        <div class="col-md-6 text-normal element-top-0 element-bottom-20 os-animation"
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <h2 class="sub-title head-title element-top-60">We offer following consulting services</h2>
                            <div class="divider-border divider-border-left element-top-10 element-bottom-10">
                                <div class="divider-border-inner"></div>
                            </div>
                            <div>
                                <ul class="web-design-list text-content">
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Detail Design such as Architectural, Structural and General Civil Design and drawings.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Preliminary Estimate for a project for budgeting.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Preparation of Enquiry documents for Civil / Structural contractors.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Preparation of design philosophies such as foundation design basis, Design of various facilities as per standards.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Analysis, design and drawings for various RCC and Steel Structures such as Equipment Foundations, Pipe Rack, Technological Structures, Compressor Shed, Substation, Plant and Non-Plant buildings,etc.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Providing and control of MTO for cement, rebar and structural steel at various stages.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Proposal works including estimation of BOQ, preliminary design, preparation for proposal documents etc.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>3D model of plant.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Fabrication Drawings.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Peer check for calculations/ drawings prepared by other consultants.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</div>