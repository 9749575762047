<div id="content" role="main">
    <article>
        <section class="section pmc-content">
            <div class="background-media"> </div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn"
                        data-os-animation-delay="0s">
                        <h1 class="text-center element-top-80 element-bottom-20 text-light" data-os-animation="none"
                            data-os-animation-delay="0s">Career</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20"
                            data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="service-title head-title text-left element-top-60 os-animation normal regular" data-os-animation="fadeInRight"
                            data-os-animation-delay="0.1s">Career</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation"
                            data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight"
                            data-os-animation-delay="0.3s">
                            <p class="text-content">We  offer good oportunity for Experienced as well as Fresh Engineers and CAD operators.
                            </p>
                            <p class="text-content">On job Training is provided to Engineers working at ShreeSidhi.</p>
                            <strong>Currently, we do not have new opening.</strong>
                            <p class="text-content">But one can apply for our future requirement.</p>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft"
                            data-os-animation-delay="0s">
                            <img alt="p11" src="../../assets/images/carrer-img.png">
                        </div>
                    </div> 
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="list-container row element-top-0 os-animation"
                        data-os-animation="fadeInUp" data-os-animation-delay="0.2s">

                        <!-- <div class="col-md-6 text-normal element-top-0 element-bottom-20 text-center os-animation "
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <img class="element-top-60" alt="p11" src="../../assets/images/Constructionandmultyservices.jpg">
                        </div> -->

                        <div class="col-md-6 text-normal element-top-0 element-bottom-20 os-animation"
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <h2 class="service-title head-title element-top-60">Openings</h2>
                            <div class="divider-border divider-border-left element-top-10 element-bottom-10">
                                <div class="divider-border-inner"></div>
                            </div>
                            <div class="text-content">Experienced Engineers Civil/ Mech/ Electrical/Structural ( 1 to 5 years)</div>
                            <div class="text-content">Qualification : BE/B.Tech or ME/ M. Tech in Civil/Structural/ Mech /Elec. Engineering</div>
                            <div class="text-content">Experience :</div>
                            <div class="text-content">
                                <ul class="web-design-list">
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i> Analysis and design of various steel and RC structures such as Pipe Rack, Technological Structures/ Plant buildings, Non-plant buildings, Equipment foundations,</li>
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i> Foundations such as cast-in situ/ pre-cast pile foundations, isolated foundations, combined foundations, raft foundations, machine foundations(block type) , etc.</li>
                                
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Co-ordination with client.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</div>