<div id="content" role="main">
    <article>
        <section class="section">
            <div class="background-media"
                style="background-image: url(assets/images/construction/news.jpg); background-repeat:no-repeat; background-size:cover; background-attachment:fixed; background-position: 50% 0%;">
            </div>
            <div class="background-overlay grid-overlay-0" style="background-color: rgba(48,60,64,0);"></div>
            <div class="container">
                <div class="row">
                    <header class="blog-header small-screen-center text-light col-md-12">
                        <h1 class="text-left element-top-80 element-bottom-80 text-light light" data-os-animation="none"
                            data-os-animation-delay="0s">Latest News</h1>
                    </header>
                </div>
            </div>
        </section>
        <!-- News -->
        <section class="section">
            <div class="container">
                <div class="row element-top-50 element-bottom-50">
                    <div class="col-md-9 blog-list-layout-normal">
                        <article class="post">
                            <div class="post-media">
                                <div class="figure fade-in text-center figcaption-middle"> <a class="figure-image"
                                        href="corporate-standard-post.html">
                                        <img alt="The know how of branding" src="assets/images/construction/project1.jpg">

                                        <div class="figure-overlay">
                                            <div class="figure-overlay-container">
                                                <div class="figure-caption">
                                                    <span class="figure-overlay-icons">
                                                        <i class="icon-link"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a> </div>
                            </div>
                            <header class="post-head small-screen-center">
                                <h2 class="post-title"><a href="corporate-standard-post.html" rel="bookmark"
                                        title="Permalink to The know how of branding">The
                                        know how of branding</a></h2>
                                <div class="post-details"> <span class="post-date">
                                        <i class="icon-clock"></i> September 25, 2014</span> <span class="post-author">
                                        <i class="icon-head"></i>
                                        <a href="corporate-standard-post.html">
                                            Proistakis Manos
                                        </a>
                                    </span> <span class="post-category">
                                        <i class="icon-clipboard"></i>
                                        <a href="corporate-no-sidebar.html" rel="tag">Arts &amp; Culture</a>,
                                        <a href="corporate-no-sidebar.html" rel="tag">Tech</a>
                                    </span> <span class="post-link">
                                        <i class="icon-speech-bubble"></i>
                                        <a href="#" title="Comment on The know how of branding">2 comments
                                        </a>
                                    </span> </div>
                            </header>
                            <div class="post-body">
                                <p>Lisa, vampires are make-believe, like elves, gremlins, and <strong>Eskimos</strong>.
                                    We started out like Romeo and Juliet, but it ended up in tragedy. Attempted murder?
                                    Now honestly, what is that? Do they give a Nobel
                                    <strong>Prize</strong> for attempted chemistry? Get ready, skanks! It&#8217;s time
                                    for the truth train! Books are useless! I only ever read one book, &#8220;To Kill A
                                    Mockingbird,&#8221; and it gave me absolutely
                                    no insight on how to kill mockingbirds! Sure it taught me not to judge a man by the
                                    color of his skin…but what good does *that* do me?</p>
                            </div>
                            <div class="small-screen-center post-more"> <a class="post-more-link btn btn-primary"
                                    href="corporate-standard-post.html">
                                    read more</a> </div>
                        </article>
                        <!-- <div class="text-center">
                            <ul class="post-navigation pagination">
                                <li class="disabled"> <a>&lsaquo;</a> </li>
                                <li class="active"><span class="current">1</span></li>
                                <li> <a class="inactive" href="corporate-no-sidebar.html">
                                        2</a> </li>
                                <li> <a href="corporate-no-sidebar.html">
                                        &rsaquo;</a> </li>
                            </ul>
                        </div> -->
                    </div>
                    <div class="col-md-3 sidebar ">
                        <div class="sidebar-widget widget_recent_entries">
                            <h3 class="sidebar-header">Recent Posts</h3>
                            <ul>
                                <li class="clearfix">
                                    <div class="post-icon"> <a href="corporate-standard-post.html"
                                            title="The know how of branding">
                                            <img alt="blog01" class="attachment-thumbnail" height="150"
                                                src="assets/images/construction/project1.jpg" width="150">
                                        </a> </div> <a href="corporate-standard-post.html"
                                        title="The know how of branding">The know how of
                                        branding</a> <small class="post-date">September 25,
                                        2014</small>
                                </li>
                            </ul>
                        </div>
                        <div class="sidebar-widget widget_tag_cloud">
                            <h3 class="sidebar-header">Tags</h3>
                            <div class="tagcloud">
                                <ul>
                                    <li> <a href='#'>assault</a> </li>
                                    <li> <a href='#'>billionaire</a> </li>
                                </ul>
                            </div>
                        </div>
                        <div class="sidebar-widget widget_categories" id="categories-5">
                            <h3 class="sidebar-header">Categories</h3>
                            <ul>
                                <li class="cat-item cat-item-6"> <a href="corporate-no-sidebar.html">
                                        News</a> 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end -->
    </article>
</div>