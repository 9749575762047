import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    jQuery(document).ready(function()
  {
    jQuery("#layerslider,#layerslider2,#layerslider3").layerSlider({
        responsive: true,
        responsiveUnder: 1280,
        layersContainer: 1170,
        skin: 'fullwidth',
        hoverPrevNext: true,
        skinsPath: 'layerslider/skins/'
    });
      jQuery('#homepage-slider').revolution(
      {
          jsFileLocation: '/scripts/',
          extensions: 'revolution-extensions/',
          sliderType: "standard",
          sliderLayout: "auto",
          dottedOverlay: "none",
          delay: 9000,
          navigation:
          {
              keyboardNavigation: "off",
              keyboard_direction: "horizontal",
              mouseScrollNavigation: "off",
              onHoverStop: "off",
              touch:
              {
                  touchenabled: "on",
                  swipe_threshold: 75,
                  swipe_min_touches: 1,
                  swipe_direction: "horizontal",
                  drag_block_vertical: false
              },
              arrows:
              {
                  style: "",
                  enable: true,
                  hide_onmobile: false,
                  hide_onleave: false,
                  tmp: '',
                  left:
                  {
                      h_align: "left",
                      v_align: "center",
                      h_offset: 20,
                      v_offset: 0
                  },
                  right:
                  {
                      h_align: "right",
                      v_align: "center",
                      h_offset: 20,
                      v_offset: 0
                  }
              },
              bullets:
              {
                  enable: true,
                  hide_onmobile: false,
                  style: "hesperiden",
                  hide_onleave: false,
                  direction: "horizontal",
                  h_align: "center",
                  v_align: "bottom",
                  h_offset: 0,
                  v_offset: 20,
                  space: 5,
                  tmp: ''
              }
          },
          gridwidth: 1170,
          gridheight: 550,
          lazyType: "none",
          parallax:
          {
              type: "mouse+scroll",
              origo: "enterpoint",
              speed: 300,
              levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
          },
          shadow: 0,
          spinner: "spinner2",
          stopLoop: "off",
          stopAfterLoops: -1,
          stopAtSlide: -1,
          shuffle: "off",
          autoHeight: "off",
          hideThumbsOnMobile: "off",
          hideSliderAtLimit: 0,
          hideCaptionAtLimit: 0,
          hideAllCaptionAtLilmit: 0,
          debugMode: false,
          fallbacks:
          {
              simplifyAll: "off",
              nextSlideOnWindowFocus: "off",
              disableFocusListener: false,
          }
      });
        jQuery("#news-slider8").owlCarousel({
            items : 4,
            itemsDesktop:[1199,4],
            itemsDesktopSmall:[980,3],
            itemsMobile : [600,1],
            autoPlay:true
        });
  });
  }

}
