<div id="content" role="main">
    <article>
        <section class="section pmc-content">
            <div class="background-media"> </div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn"
                        data-os-animation-delay="0s">
                        <h1 class="text-center element-top-80 element-bottom-20 text-light" data-os-animation="none"
                            data-os-animation-delay="0s">Contact Us</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20"
                            data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
            
        </section>

        <!-- contact us -->
        <section class="section">
            <div class="divider-wrapper">
                <div class="visible-xs element-height-50"></div>
                <div class="visible-sm element-height-50"></div>
                <div class="visible-md element-height-50"></div>
                <div class="visible-lg element-height-50"></div>
            </div>
            <div class="container head-title service-title whatWedo-section h2">
            We'd love to hear from you!
            Brief us your requirements below, and let's connect.
            </div>
        </section>
       
        <section class="section">
            <div class="background-overlay grid-overlay-0" style="background-color: rgba(255,255,255,0);"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        
                        <div class="row">
                            <div class="col-md-6 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.2s">
                                <div class="divider-wrapper">
                                    <div class="visible-xs element-height-50"></div>
                                    <div class="visible-sm element-height-50"></div>
                                    <div class="visible-md element-height-50"></div>
                                    <div class="visible-lg element-height-50"></div>
                                </div>
                                <div class="wpcf7" dir="ltr" id="wpcf7-f273-p263-o1" lang="en-US">
                                    <div class="screen-reader-response"></div>
                                    <form class="contact-form" [formGroup]="registerForm">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group form-icon-group"> 
                                                    <i class="fa fa-user"></i>
                                                    <input class="form-control"  formControlName="userName"
                                                     name="userName"
                                                    placeholder="Your name *" type="text" required=""> 
                                                    <span class="text-danger"
                                                    *ngIf="(registerFormControl.userName.touched || submitted) && registerFormControl.userName.errors?.required">
                                                    Name is required
                                                </span>
                                                <span class="text-danger"
                                                *ngIf="(registerFormControl.userName.touched || submitted) && registerFormControl.userName.errors?.pattern">
                                                Only characters are allowed
                                                </span>
                                                <span class="text-danger"
                                                *ngIf="(registerFormControl.userName.touched || submitted) && registerFormControl.userName.errors?.maxlength">
                                                Only 50 characters are allowed
                                                </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group form-icon-group"> 
                                                    <i class="fa fa-envelope"></i> 
                                                    <input class="form-control" formControlName="userEmail"
                                                     placeholder="Your email *"
                                                     type="email" required=""> 
                                                     <span class="text-danger"
                                                     *ngIf="(registerFormControl.userEmail.touched || submitted) && registerFormControl.userEmail.errors?.required">
                                                     Email is required
                                                 </span>
                                                 <span class="text-danger"
                                                     *ngIf="registerFormControl.userEmail.touched && registerFormControl.userEmail.errors?.email">
                                                     Enter a valid email address
                                                 </span>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group form-icon-group"> 
                                                    <i class="fa fa-file-text"></i>
                                                     <input class="form-control"  formControlName="userSub"
                                                      placeholder="Your subject *"
                                                      type="text" required=""> 
                                                      <span class="text-danger"
                                                      *ngIf="(registerFormControl.userSub.touched || submitted) && registerFormControl.userSub.errors?.required">
                                                      Subject is required
                                                  </span>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="form-group form-icon-group"> 
                                            <i class="fa fa-pencil"></i> 
                                            <textarea class="form-control" formControlName="userMessage"
                                             placeholder="Your message *" 
                                             rows="10" required=""></textarea>
                                             <span class="text-danger"
                                             *ngIf="(registerFormControl.userMessage.touched || submitted) && registerFormControl.userMessage.errors?.required">
                                             Message is required
                                            </span>
                                             </div> 
        
                                            <!-- <input type="submit" value="Send email" class="btn btn-primary">
                                        <div class="messages text-center"></div> -->
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-success contact-btn" (click)="onSubmit()">Submit</button>
                                        </div>
                                    </form>        
                                </div>
                                <div class="divider-wrapper">
                                    <div class="visible-xs element-height-60"></div>
                                    <div class="visible-sm element-height-60"></div>
                                    <div class="visible-md element-height-60"></div>
                                    <div class="visible-lg element-height-60"></div>
                                </div>
                            </div>
                            <div class="col-md-6 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.2s">
                                <div class="divider-wrapper">
                                    <div class="visible-xs element-height-50"></div>
                                    <div class="visible-sm element-height-50"></div>
                                    <div class="visible-md element-height-50"></div>
                                    <div class="visible-lg element-height-50"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <div class="conatct-info-box">
                                            <div class="inner-box">
                                                <img src="../../assets/images/CP-location-icon.png" alt="" >
                                                <div class="divider-line"></div>
                                                <p><a href="tel:+91 777 503 2331"><b>Head Office Address:</b> 401, Neelsidhi prime, building no 24, plot no 13, road no 1, sector no 18, New Panvel, 410206</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div class="conatct-info-box">
                                            <div class="inner-box">
                                                <img src="../../assets/images/CP-location-icon.png" alt="">
                                                <div class="divider-line"></div>
                                                <p><b>Branch Office Address:</b> 1st floor, Warana Prasad, Shivaginagar, Shigaon, Tal- Walawa, Dist. Sangali.416302</p>
                                            </div>
                                        </div>
                                    </div>
                      
                                </div>
                    
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
       <section> <div class="QuestionsLook-here" id="Questions">
            <div class="website-design-question">
                <div class="row">
                    <div class="col-md-2 col-sm-2">
                    </div>
                    <div class="col-md-8 col-sm-8">
                        <div class="home-contact-info">
                            <img src="../assets/images/Logo.png" alt="Walstar" class="responsive-img">
                            <p class="question">Questions? Look here.</p>
                            <p class="p1">Can’t find answer? call us at <a href="tel:+91 777 503 2331"><span class="question">+91 9763716548,8767098781</span></a></p>
                            <p>or email us <a href="mailto:info@walstartechnologies.com"><span class="question">contact@shreesidhi.co.in</span></a></p>
                            
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2">
                    </div>
                </div>
            </div>
        </div>
    </section>
        <div class="website-design-summary">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="inverse-text">
                            We strive to deliver solutions highest level of consistency in 
                            performance and quality
                        </h3>
                        <h4 class="inverse-text">
                            Call us today on +91 9763716548,8767098781 or email at </h4>
                          <p class="email">contact@shreesidhi.co.in , aspatil@shreesidhi.co.in , uspharne@shreesidhi.co.in , supharne@shreesidhi.co.in </p>
                        
                     </div>
                </div>
            </div>

        </div>
    </article>
</div>