import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from  '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DesignDetailComponent } from './design-detail/design-detail.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ServicesComponent } from './services/services.component';
import { ProjectsComponent } from './projects/projects.component';
import { ClientsComponent } from './clients/clients.component';
import { MachineryComponent } from './machinery/machinery.component';
import { PmcComponent } from './pmc/pmc.component';
import { MultiServicesComponent } from './multi-services/multi-services.component';
import { NewsComponent } from './news/news.component';
import { CareerComponent } from './career/career.component';
import { TradingComponent } from './trading/trading.component';
import { TechnicalTrainingComponent } from './technical-training/technical-training.component';
import { StructuralAuditingComponent } from './structural-auditing/structural-auditing.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DesignDetailComponent,
    AboutUsComponent,
    ContactUsComponent,
    ServicesComponent,
    ProjectsComponent,
    ClientsComponent,
    MachineryComponent,
    PmcComponent,
    MultiServicesComponent,
    NewsComponent,
    CareerComponent,
    TradingComponent,
    TechnicalTrainingComponent,
    StructuralAuditingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
