<div id="content" role="main">
    <article>
        <section class="section project-content">
            <div class="background-media"> </div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn" data-os-animation-delay="0s">
                        <h1 class="text-center element-top-80 element-bottom-20 text-light" data-os-animation="none" data-os-animation-delay="0s">Projects</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <!-- Projects -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0s"> 
                        <img class="content-corner" alt="p11" src="../../assets/images/competitivePeople.png">
                    </div>
                    </div>
                    <div class="col-md-6">
                        <h2 class="head-title head-title text-left element-top-60 os-animation normal regular" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">Project 1</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.3s">
                            <p class="text-content">We are start-up now and we will update this soon..</p>
                        </div>
                        <!-- <ul class="text-content skills-list normal element-top-20 element-bottom-20 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.4s">
                            <li>Web Design</li>
                            <li>Logo Design</li>
                            <li>SEO optimisation</li>
                            <li>Content architecture</li>
                            <li>Motion picture</li>
                        </ul> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="head-title  head-title text-left element-top-60 os-animation normal regular" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">Project 2</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.3s">
                            <p class="text-content">We are start-up now and we will update this soon.</p>
                        </div>
                        <!-- <ul class="text-content skills-list normal element-top-20 element-bottom-20 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.4s">
                            <li>Web Design</li>
                            <li>Logo Design</li>
                            <li>SEO optimisation</li>
                            <li>Content architecture</li>
                            <li>Motion picture</li>
                        </ul> -->
                    </div>
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0s">
                        <img class="content-corner" alt="p11" src="../../assets/images/construction/project1.jpg">
                     </div>
                    </div> 
                </div>
                <!-- <div class="row">
                    <div class="col-md-9">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0s"> 
                        <img alt="p11" src="assets/images/construction/project1.jpg">
                    </div>
                    </div>
                    <div class="col-md-3">
                        <h2 class="head-title text-left element-top-60 os-animation normal regular" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">Project 3</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.3s">
                            <p class="text-content">Efficiently unleash cross-media <strong>information</strong> without cross-media value. Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions.</p>
                        </div>
                        <ul class="text-content skills-list normal element-top-20 element-bottom-20 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.4s">
                            <li>Web Design</li>
                            <li>Logo Design</li>
                            <li>SEO optimisation</li>
                            <li>Content architecture</li>
                            <li>Motion picture</li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </section>
    </article>
</div>