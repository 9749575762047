<div id="content" role="main">
    <article>
        <section class="section pmc-content">
            <div class="background-media"> </div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn" data-os-animation-delay="0s">
                        <h1 class="text-center  element-top-80 element-bottom-20 text-light" data-os-animation="none" data-os-animation-delay="0s">Project Management Consultancy(PMC)</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <!-- <div class="col-text-1 text-light element-top-0 element-bottom-80" data-os-animation="none" data-os-animation-delay="0s">
                            <p style="text-align: center;"><strong>Lambda</strong> is an international highly diversified company focused on Infrastructure, Building, Industrial, MEP, Solar Power Construction, EPC and Concession Contracts, Facility and Project Management. Our vision
                                is to enhance further our role as a worldwide construction group by securing sustainable future development through continuous improvement of services based on our values such as integrity, teamwork, responsibility
                                and transparency.</p>
                        </div> -->
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <!--pmc-summary-section  -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="text-left head-title element-top-60 os-animation normal regular service-title" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">Project Management Consultancy(PMC)</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.3s">
                            <p class="text-content">We assist organization as PMC for doing the right projects- that make the best use of your time, talent and budget – and doing them right way. We can manage your project from initiation to closer. If your current project is lagging milestone or lacking momentum contact us for our services.</p>
                        </div>
                   
                    </div>
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0s"> 
                        <img alt="p11" src="../../assets/images/pmc.jpg">
                    </div>
                    </div>
                </div>
                </div>
        </section>
<!-- service section -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="head-title  head-title text-center element-top-70 os-animation big" data-os-animation="fadeIn" data-os-animation-delay="0s"> Service features</h1>
                        <div class="divider-border divider-border-center element-top-10 element-bottom-10 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.1s" style="height:3px;">
                            <div class="divider-border-inner" style="width:60px;"></div>
                        </div>
                        <p class="text-content lead text-center center element-bottom-40 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.2s"> Take a look at these awesome features we provide.<br> More will be added in the future.</p>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="figure element-top-40 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0.1s"> <span class="figure-image">
                                <img alt="devices-4.png" src="../../assets/images/business-management-consulting-consultant-service-infograph.jpg">
                            </span> </div>
                            </div>
                            <div class="col-md-6">
                                <ul class="features-list element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                                    <li class="element-top-20 element-bottom-20 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0s">
                                        <div class="features-list-icon box-animate"> <i class="icon-monitor" style="color:#ffffff;"></i> </div>
                                        <h3 class="feature-title">Responsive design</h3>
                                        <p class="text-content">Lambda is designed to look cool on any device from mobile and tablets to desktop computers and also on retina screens.</p>
                                    </li>
                                    <li class=" element-top-20 element-bottom-20 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">
                                        <div class="features-list-icon box-animate"> <i class="icon-repeat" style="color:#ffffff;"></i> </div>
                                        <h3 class="feature-title">Free updates</h3>
                                        <p class="text-content">With Lambda you get a free updates forever. Improvements and features will be added to make the theme future proof.</p>
                                    </li>
                                    <li class=" element-top-20 element-bottom-90 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">
                                        <div class="features-list-icon box-animate"> <i class="icon-eye" style="color:#ffffff;"></i> </div>
                                        <h3 class="feature-title">Stunning pages</h3>
                                        <p class="text-content">Create stunning pages which look great on any device. Clean HTML5 and CSS, beautifully coded &amp; loads of features.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</div>
        