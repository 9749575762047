<div class="pace-overlay"></div>
<!-- <div class="top-bar">
    <div class="container">
        <div class="top top-left">
            <div class="sidebar-widget text-left small-screen-center widget_text">
                <div class="textwidget"> <i class="fa fa-phone"></i> &nbsp; 0-000-000-000 </div>
            </div>
            <div class="sidebar-widget text-left small-screen-center widget_text">
                <div class="textwidget"> <i class="fa fa-envelope"></i>&nbsp; <a href="mailto:sai-shree@sai-shree.com">shreesiddhi@gmail.com</a> </div>
            </div>
        </div>
        <div class="top top-right">
            <div class="sidebar-widget text-right small-screen-center widget_social">
                <ul class="unstyled inline social-icons social-simple social-normal">
                    <li> <a data-iconcolor="#3b5998" href="corporate-index.html">
                        <i class="fa fa-facebook"></i>
                    </a> </li>
                    <li> <a data-iconcolor="#00acee" href="corporate-index.html">
                        <i class="fa fa-twitter"></i>
                    </a> </li>
                    <li> <a data-iconcolor="#5FB0D5" href="corporate-index.html">
                        <i class="fa fa-linkedin"></i>
                    </a> </li>
                    <li> <a data-iconcolor="#E45135" href="corporate-index.html">
                        <i class="fa fa-google-plus"></i>
                    </a> </li>
                </ul>
            </div>
        </div>
    </div>
</div> -->
<!-- Navbar -->
<div class="sticky-wrapper">
    <div class="menu navbar navbar-static-top header-logo-left-menu-right oxy-mega-menu navbar-sticky" id="masthead">
        <div class="container">
            <div class="navbar-header"> <button class="navbar-toggle collapsed" data-target=".main-navbar" data-toggle="collapse" type="button">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button> <a class="navbar-brand" [routerLink]="['']" >
                <img src="../assets/images/Logo.png" alt="Shree Siddhi Pvt Ltd" title="">
            </a> </div>
            <div class="nav-container">
                <nav class="collapse navbar-collapse main-navbar logo-navbar navbar-right">
                    <div class="menu-container">
                        <ul class="nav navbar-nav" id="menu-main">
                            <li class="menu-item active"> <a [routerLink]="['']" >Home</a> </li>
                            <li class="menu-item "> <a [routerLink]="['/About-Us']">About Us</a> </li>
                            <li class="menu-item  dropdown "> <a class="dropdown-toggle" data-toggle="dropdown" >Services</a>
                                <ul class="dropdown-menu dropdown-menu-left ">
                                    <li class="menu-item"> <a [routerLink]="['/Design-Details']">Design and Detail Engineering Services</a> </li>
                                    <li class="menu-item"> <a [routerLink]="['/PMC']">Project Management Consultancy(PMC)</a> </li>
                                    <li class="menu-item"> <a [routerLink]="['/Multi-Services']">Multi Services/Contracting-Civil/Mech/Electrical </a> </li>
                                    <li class="menu-item"> <a [routerLink]="['/Trading']">Trading & Distribution</a> </li>
                                    <li class="menu-item"> <a [routerLink]="['/Technical-Training']">Technical Training/Manpower Services</a> </li>
                                    <li class="menu-item"> <a [routerLink]="['/Structural-Auditing']">Structural Auditing </a> </li>
                                </ul>
                            </li>
                            <li class="menu-item "> <a [routerLink]="['/Projects']">Projects</a> </li>
                            <li class="menu-item "> <a [routerLink]="['/Career']">Career</a> </li>
                            <!-- <li class="menu-item "> <a [routerLink]="['/News']">News</a> </li> -->
                            <li class="menu-item "> <a [routerLink]="['/Contact-Us']">Contact Us</a> </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<!-- Footer -->
<footer id="footer">
    <section class="section">
        <div class="container">
            <div class="row element-top-40 element-bottom-40 footer-columns-4">
                <div class="col-sm-2">
                    <div class="sidebar-widget widget_categories">
                        <h3 class="sidebar-header">Links</h3>
                        <ul>
                          <li><a [routerLink]="['']" class="text-content">Home</a><span class="border"></span></li>
                          <li><a [routerLink]="['/About-Us']" class="text-content">About Us</a><span class="border"></span></li>
                          <li><a [routerLink]="['/PMC']" class="text-content">Services</a><span class="border"></span></li>
                          <li><a [routerLink]="['/Projects']" class="text-content">Project</a><span class="border"></span></li>
                          <li><a [routerLink]="['/Career']" class="text-content">Career</a><span class="border"></span></li>
                          <!-- <li><a [routerLink]="['/News']">News</a><span class="border"></span></li> -->
                          <li><a [routerLink]="['/Contact-Us']" class="text-content">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="sidebar-widget woocommerce widget_product_tag_cloud">
                        <h3 class="sidebar-header">Areas Of Expertise</h3>
                        <div class="tagcloud">
                            <ul>
                                <li> <a class="text-content" [routerLink]="['/Design-Details']" style="font-size: 11.5pt;" title="4 topics">Design and Detailing Engineering Services</a> </li>
                                <li> <a class="text-content" [routerLink]="['/PMC']" style="font-size: 16.75pt;" title="6 topics">Project Management Consultancy(PMC)</a> </li>
                                <li> <a class="text-content" [routerLink]="['/Multi-Services']" style="font-size: 16.75pt;" title="6 topics">Multi Services/Contracting-Civil/Mech/Electrical</a> </li>
                                <li> <a class="text-content" [routerLink]="['/Trading']" style="font-size: 11.5pt;" title="4 topics">Trading & Distribution</a> </li>
                                <li> <a class="text-content" [routerLink]="['/Technical-Training']" style="font-size: 11.5pt;" title="4 topics">Technical Training/Manpower Services</a> </li>
                                <li> <a class="text-content"[routerLink]="['/Structural-Auditing']" style="font-size: 11.5pt;" title="4 topics">Structural Auditing</a> </li>
                            </ul>
                        </div>
                        <!-- <a href="#" class="footer-readmore-expertise"><i class="fa fa-plus"></i> Read more... </a> -->
                    </div>
                </div>
                
                <div class="col-sm-6">
                    <div class="sidebar-widget widget_text">
                        <h3 class="sidebar-header">Our Headquarters</h3>
                        <div class="textwidget">

<iframe class="content-corner" width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=401,%20Neelsidhi%20prime,%20building%20no%2024,%20plot%20no%2013,%20road%20no%201,%20sector%20no%2018,%20New%20Panvel,%20410206+(ShreeSidhi)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244640.08848339107!2d74.00438750216883!3d16.651775342333778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x48f09549adb4bb52!2sSiddhi%20Home%20Appliances!5e0!3m2!1sen!2sin!4v1599412089982!5m2!1sen!2sin" width="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
                            <p><span class="text-content" style="color: #ef7f1a"> <b>Head Office Address:</b></span> 401, Neelsidhi prime, building no 24, plot no 13, road no 1, sector no 18, New Panvel, 410206</p>
                            <p><span class="text-content" style="color: #ef7f1a"> <b>Branch Office Address:</b></span> 1st floor, Warana Prasad, Shivajinagar, Shigaon, Tal- Walawa, Dist. Sangali.416302</p>
                            <p><span class="text-content" style="color: #ef7f1a"> <b>Email : </b></span> <a>contact@shreesidhi.co.in</a><span class="text-content" style="color: #ef7f1a"> <b> Contact no:</b></span><a>9763716548,8767098781</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section subfooter">
        <div class="container">
            <div class="row element-top-10 element-bottom-10 footer-columns-2">
                <div class="col-sm-6">
                    <div class="sidebar-widget widget_text">
                        <div class="textwidget"><p class="powered-by">© 2020 <a href="">ShreeSidhi EPC Pvt.Ltd</a>. All Rights Reserved.</p></div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <p class="sub-footer powered-by">Powered By: <a href="#" target="_blank">dsBit Solutions</a>  </p>
                </div>
            </div>
        </div>
    </section>
</footer>
<a class="go-top go-top-circle" href="javascript:void(0)">
    <i class="fa fa-angle-up"></i>
</a>