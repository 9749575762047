<div id="content" role="main">
    <article>
        <!-- about us section -->
        <section class="section about-us-content">
            <div class="background-media"></div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3 text-center small-screen-center"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn"
                        data-os-animation-delay="0s">
                        <h1 class="head-title text-center element-top-80 element-bottom-20 text-light" data-os-animation="none"
                            data-os-animation-delay="0s">About Us</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20"
                            data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="service-title head-title text-left element-top-60 os-animation normal regular" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">About ShreeSidhi</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.3s">
                            <p class="text-content">Shreesidhi group offers engineering, project management consultancy and technical training services for various industries. We have good experienced team in detail engineering, project management for industries like power plant, refineries, fertilizes infrastructure.</p>
                        </div>
                   
                    </div>
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0s"> 
                        <img class="content-corner" alt="p11" src="assets/images/construction/project1.jpg">
                    </div>
                    </div>
                </div>
                </div>
        </section>
        <section>
            <div class="message-bar">
                <div class="container">
                    <div class="row">
                        <h3>WALK WITH US AND WE WILL GIVE YOU EXCELLENCE JOURNEY</h3>
                    </div>
                </div>
            </div>

        </section>
        <!-- our team section -->
        <section class="section our-team-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="service-title head-title text-center element-bottom-20 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.1s">Our Team</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-50 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.1s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class=" row staff-list-container list-container element-top-0">
                            <div class="figure text-normal col-md-3 fade-in element-top-0 element-bottom-20 figcaption-bottom text-center fade-in os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.2s">
                                <div class="figure-image"> 
                                    <img alt="Jasmine Foster" src="assets/images/construction/img40-notinclude.jpg">
                                    <div class="figure-overlay grid-overlay-0">
                                        <div class="figure-overlay-container">
                                            <div class="figure-caption text-center">
                                                <ul class="figure-overlay-icons social-icons social-simple">
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-facebook-official"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-linkedin"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="icon-mail"></i>
                                                </a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="figure-caption text-center">
                                    <h3 class="figure-caption-title block-title"><strong>Jasmine Foster</strong></h3>
                                    <!-- <p class="figure-caption-description block-title">Construction Manager</p> -->
                                </div>
                            </div>
                            <div class="figure col-md-3 fade-in element-top-0 element-bottom-20 figcaption-bottom text-center fade-in os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.4s">
                                <div class="figure-image"> <img alt="Earl Johnston" src="assets/images/construction/img37-notinclude.jpg">
                                    <div class="figure-overlay grid-overlay-0">
                                        <div class="figure-overlay-container">
                                            <div class="figure-caption text-center">
                                                <ul class="figure-overlay-icons social-icons social-simple">
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-facebook-official"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-linkedin"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="icon-mail"></i>
                                                </a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="figure-caption text-center">
                                    <h3 class="figure-caption-title block-title"><strong>Earl Johnston</strong></h3>
                                    <!-- <p class="figure-caption-description block-title">Building Worker</p> -->
                                </div>
                            </div>
                            <div class="figure text-normal col-md-3 fade-in element-top-0 element-bottom-20 figcaption-bottom text-center fade-in os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.6s">
                                <div class="figure-image"> <img alt="Cody Maxwell" src="assets/images/construction/img35-notinclude.jpg">
                                    <div class="figure-overlay grid-overlay-0">
                                        <div class="figure-overlay-container">
                                            <div class="figure-caption text-center">
                                                <ul class="figure-overlay-icons social-icons social-simple">
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-facebook-official"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-linkedin"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="icon-mail"></i>
                                                </a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="figure-caption text-center">
                                    <h3 class="figure-caption-title block-title"><strong>Cody Maxwell</strong></h3>
                                    <!-- <p class="figure-caption-description block-title">Project Manager</p> -->
                                </div>
                            </div>
                            <div class="figure col-md-3 fade-in element-top-0 element-bottom-20 figcaption-bottom text-center fade-in os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.8s">
                                <div class="figure-image"> <img alt="Alton Clarke" src="assets/images/construction/img35-notinclude.jpg">
                                    <div class="figure-overlay grid-overlay-0">
                                        <div class="figure-overlay-container">
                                            <div class="figure-caption text-center">
                                                <ul class="figure-overlay-icons social-icons social-simple">
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-facebook-official"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="fa fa-linkedin"></i>
                                                </a> </li>
                                                    <li> <a href="#" target="_blank">
                                                    <i class="icon-mail"></i>
                                                </a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="figure-caption text-center">
                                    <h3 class="figure-caption-title block-title"><strong>Alton Clarke</strong></h3>
                                    <!-- <p class="figure-caption-description block-title">Building Worker</p> -->
                                </div>
                                
                            </div>
             
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </article>
</div>