<div id="content" role="main">
    <article>
        <!--Responsive header-->
<!-- <div id="layerslider-container-fw">
	<div id="layerslider" style="width: 100%; height: 530px; margin: 0px auto; ">
		<div class="ls-slide" data-ls="transition2d:12;timeshift:-1000;">			
			<img src="../../assets/images/slider3.jpg" class="ls-bg" alt="Slide background">
				<h3 class="ls-l slide3" style="top:196px; left:100px; font-family: roboto; color: #FFFFFF; line-height:22px; font-size:32px; background:rgba(0,0,0,0.85); padding:18px 30px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; showuntil:2000; fadeout:false;"> 
                    Design and Detailing Engineering Services</h3>			
				<h4 class="ls-l slide3" style="text-transform: capitalize;top:265px; left:100px; background:#ff6600; font-family: roboto; color: #FFFFFF; font-size:28px; line-height:26px; padding:10px 20px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; delayin:500; showuntil:2500;fadeout:false;">
					Designing future with excellence<br>Engineering with value	</h4>		
		</div>
		
		<div class="ls-slide" data-ls="transition2d:35;timeshift:-1000;">			
            <img src="../../assets/images/slider.jpg" class="ls-bg" alt="Slide background">
            <h3 class="ls-l slide3" style="top:196px; left:100px; font-family: roboto; color: #FFFFFF; line-height:22px; font-size:32px; background:rgba(0,0,0,0.85); padding:18px 30px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; showuntil:2000; fadeout:false;"> 
                Project management consultancy</h3>			
            <h4 class="ls-l slide3" style="text-transform: capitalize;top:265px; left:100px; background:rgba(152,212,96,0.85); font-family: roboto; color: #FFFFFF; font-size:28px; line-height:26px; padding:10px 20px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; delayin:500; showuntil:2500;fadeout:false;">
                Best place for best people<br>Changing your perception</h4>	
		</div>
		
		<div class="ls-slide" data-ls="transition3d:75;timeshift:-1000;">			
			<img src="../../assets/images/Designdetailengg..jpeg" class="ls-bg" alt="Slide background">
            
            <h3 class="ls-l slide3" style="top:196px; left:100px; font-family: roboto; color: #FFFFFF; line-height:22px; font-size:32px; background:rgba(0,0,0,0.85); padding:18px 30px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; showuntil:2000; fadeout:false;"> 
                Multi Services /Contracting</h3>			
            <h4 class="ls-l slide3" style="text-transform: capitalize;top:265px; left:100px; background:#ff6600; font-family: roboto; color: #FFFFFF; font-size:28px; line-height:26px; padding:10px 20px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; delayin:500; showuntil:2500;fadeout:false;">
                Stay ahead Change the world<br>We create for you</h4>	
        </div>
        
		 <div class="ls-slide" data-ls="transition2d:63;timeshift:-1000;">			
            <img src="../../assets/images/training.jpg" class="ls-bg" alt="Slide background">
            <h3 class="ls-l slide3" style="top:196px; left:100px; font-family: roboto; color: #FFFFFF; line-height:22px; font-size:32px; background:rgba(0,0,0,0.85); padding:18px 30px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; showuntil:2000; fadeout:false;"> 
                Trading and Distribution</h3>			
            <h4 class="ls-l slide3" style="text-transform: capitalize;top:265px; left:100px; background:#230674; font-family: roboto; color: #FFFFFF; font-size:28px; line-height:26px; padding:10px 20px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; delayin:500; showuntil:2500;fadeout:false;">
                Better decision with real insights</h4>	
		</div>
    
        <div class="ls-slide" data-ls="transition2d:12;timeshift:-1000;">			
			<img src="../../assets/images/Design and detail engg.jpeg" class="ls-bg" alt="Slide background">
				<h3 class="ls-l slide3" style="top:196px; left:100px; font-family: roboto; color: #FFFFFF; line-height:22px; font-size:32px; background:rgba(0,0,0,0.85); padding:18px 30px; border-radius:3px;" data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; durationin:1500; durationout:800; showuntil:2000; fadeout:false;"> 
                    Structural Audit</h3>			
                <h4 class="ls-l slide3" style="text-transform: capitalize;top:265px; left:100px;
                 background:#ff6600; font-family: roboto; color: #FFFFFF;
                  font-size:28px; line-height:26px; padding:10px 20px; border-radius:3px;" 
                  data-ls="offsetxin:0; scalexin:0; scaleyin:0; offsetxout:0; offsetyout:top; 
                  durationin:1500; durationout:800; delayin:500; showuntil:2500;fadeout:false;">
					Real world solutions<br>Rewrite your history	</h4>		
		</div>
		
	</div>
</div> -->

        <!-- Slider Section -->
        <section class="section">
            <div class="container-fullwidth">
                <div class="row">
                    <div class="col-md-12">
                        <div class="rev_slider_wrapper fullwidthbanner-container">
                            <div id="homepage-slider" class="rev_slider" data-version="5.0">
                                <ul>
                                     <!-- SLIDE  -->
                                     <li data-description="" data-easein="default" data-easeout="default"
                                     data-index="rs-4" data-masterspeed="300" data-rotate="0"
                                     data-saveperformance="off" data-slotamount="7" data-title="Slide"
                                     data-transition="fade">
                                     <!-- MAIN IMAGE --><img alt="" class="rev-slidebg" data-bgfit="cover"
                                         data-bgparallax="off" data-bgposition="center top" data-bgrepeat="no-repeat"
                                         data-no-retina="" height="800"
                                         src="../../assets/images/slider3.jpg" width="1200">
                                     <!-- LAYERS -->
                                     <!-- LAYER NR. 1 -->
                                     <div class="tp-caption constructionwhitebig tp-resizeme rs-parallaxlevel-0"
                                         data-height="['auto']" data-responsive_offset="on" data-splitin="none"
                                         data-splitout="none" data-start="500" data-transform_idle="o:1;"
                                         data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                         data-transform_out="auto:auto;s:700;" data-width="['auto']" data-x="110"
                                         data-y="163" id="slide-4-layer-1"
                                         style="z-index: 5; white-space: nowrap; font-size: 34px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                         Design and Detailing Engineering Services </div>
                                     <!-- LAYER NR. 2 -->
                                     <div class="tp-caption constructionwhite tp-resizeme rs-parallaxlevel-0"
                                         data-end="8000" data-height="['auto']" data-responsive_offset="on"
                                         data-splitin="none" data-splitout="none" data-start="700"
                                         data-transform_idle="o:1;"
                                         data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                         data-transform_out="auto:auto;s:700;" data-voffset="" data-width="['auto']"
                                         data-x="110" data-y="center" id="slide-4-layer-2"
                                         style="z-index: 6; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                         Designing future with excellence
                                         <br>Engineering with value </div>
                                     <!-- LAYER NR. 3 -->
                                     <div class="tp-caption constructionbutton tp-resizeme rs-parallaxlevel-0"
                                         data-end="7700" data-height="['auto']" data-responsive_offset="on"
                                         data-splitin="none" data-splitout="none" data-start="900"
                                         data-transform_idle="o:1;"
                                         data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                         data-transform_out="auto:auto;s:700;" data-voffset="155"
                                         data-width="['auto']" data-x="110" data-y="bottom" id="slide-4-layer-3"
                                         style="z-index: 7; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                         <a [routerLink]="['/Design-Details']">Read more</a> </div>
                                 </li>
                                    <!-- SLIDE  -->
                                    <li data-description="" data-easein="default" data-easeout="default"
                                        data-index="rs-4" data-masterspeed="300" data-rotate="0"
                                        data-saveperformance="off" data-slotamount="7" data-title="Slide"
                                        data-transition="fade">
                                        <!-- MAIN IMAGE --><img alt="" class="rev-slidebg" data-bgfit="cover"
                                            data-bgparallax="off" data-bgposition="center top" data-bgrepeat="no-repeat"
                                            data-no-retina="" height="800"
                                            src="../../assets/images/slider.jpg" width="1200">
                                        <!-- LAYERS -->
                                        <!-- LAYER NR. 1 -->
                                        <div class="tp-caption constructionwhitebig tp-resizeme rs-parallaxlevel-0"
                                            data-height="['auto']" data-responsive_offset="on" data-splitin="none"
                                            data-splitout="none" data-start="500" data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-width="['auto']" data-x="110"
                                            data-y="163" id="slide-4-layer-1"
                                            style="z-index: 5; white-space: nowrap; font-size: 34px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Project management consultancy </div>
                                        <!-- LAYER NR. 2 -->
                                        <div class="tp-caption constructionwhite tp-resizeme rs-parallaxlevel-0"
                                            data-end="8000" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="700"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="" data-width="['auto']"
                                            data-x="110" data-y="center" id="slide-4-layer-2"
                                            style="z-index: 6; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Best place for best people<br> 
                                            Changing your perception </div>
                                        <!-- LAYER NR. 3 -->
                                        <div class="tp-caption constructionbutton tp-resizeme rs-parallaxlevel-0"
                                            data-end="7700" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="900"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="155"
                                            data-width="['auto']" data-x="110" data-y="bottom" id="slide-4-layer-3"
                                            style="z-index: 7; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            <a [routerLink]="['/PMC']">Read more</a> </div>
                                    </li>
                                    <li data-description="" data-easein="default" data-easeout="default"
                                        data-index="rs-5" data-masterspeed="300" data-rotate="0"
                                        data-saveperformance="off" data-slotamount="7" data-title="Slide"
                                        data-transition="fade">
                                        <!-- MAIN IMAGE --><img alt="" class="rev-slidebg" data-bgfit="cover"
                                            data-bgparallax="off" data-bgposition="center top" data-bgrepeat="no-repeat"
                                            data-no-retina="" height="800"
                                            src="../../assets/images/Designdetailengg..jpeg" width="1200">
                                        <!-- LAYERS -->
                                        <!-- LAYER NR. 1 -->
                                        <div class="tp-caption constructionwhitebig tp-resizeme rs-parallaxlevel-0"
                                            data-height="['auto']" data-responsive_offset="on" data-splitin="none"
                                            data-splitout="none" data-start="500" data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-width="['auto']" data-x="110"
                                            data-y="163" id="slide-4-layer-1"
                                            style="z-index: 5; white-space: nowrap; font-size: 34px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Multi Services /Contracting </div>
                                        <!-- LAYER NR. 2 -->
                                        <div class="tp-caption constructionwhite tp-resizeme rs-parallaxlevel-0"
                                            data-end="8000" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="700"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="" data-width="['auto']"
                                            data-x="110" data-y="center" id="slide-4-layer-2"
                                            style="z-index: 6; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Stay ahead Change the world<br>
                                            We create for you </div>
                                        <!-- LAYER NR. 3 -->
                                        <div class="tp-caption constructionbutton tp-resizeme rs-parallaxlevel-0"
                                            data-end="7700" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="900"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="155"
                                            data-width="['auto']" data-x="110" data-y="bottom" id="slide-4-layer-3"
                                            style="z-index: 7; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            <a [routerLink]="['/Multi-Services']">Read more</a> </div>
                                    </li>
                                    <li data-description="" data-easein="default" data-easeout="default"
                                        data-index="rs-6" data-masterspeed="300" data-rotate="0"
                                        data-saveperformance="off" data-slotamount="7" data-title="Slide"
                                        data-transition="fade">
                                        <!-- MAIN IMAGE --><img alt="" class="rev-slidebg" data-bgfit="cover"
                                            data-bgparallax="off" data-bgposition="center top" data-bgrepeat="no-repeat"
                                            data-no-retina="" height="800"
                                            src="../../assets/images/training.jpg" width="1200">
                                        <!-- LAYERS -->
                                        <!-- LAYER NR. 1 -->
                                        <div class="tp-caption constructionwhitebig tp-resizeme rs-parallaxlevel-0"
                                            data-height="['auto']" data-responsive_offset="on" data-splitin="none"
                                            data-splitout="none" data-start="500" data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-width="['auto']" data-x="110"
                                            data-y="163" id="slide-4-layer-1"
                                            style="z-index: 5; white-space: nowrap; font-size: 34px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Trading and Distribution </div>
                                        <!-- LAYER NR. 2 -->
                                        <div class="tp-caption constructionwhite tp-resizeme rs-parallaxlevel-0"
                                            data-end="8000" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="700"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="" data-width="['auto']"
                                            data-x="110" data-y="center" id="slide-4-layer-2"
                                            style="z-index: 6; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Better decision with real insights<br>  </div>
                                        <!-- LAYER NR. 3 -->
                                        <div class="tp-caption constructionbutton tp-resizeme rs-parallaxlevel-0"
                                            data-end="7700" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="900"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="155"
                                            data-width="['auto']" data-x="110" data-y="bottom" id="slide-4-layer-3"
                                            style="z-index: 7; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            <a [routerLink]="['/Trading']">Read more</a> </div>
                                    </li>
                                    <li data-description="" data-easein="default" data-easeout="default"
                                        data-index="rs-7" data-masterspeed="300" data-rotate="0"
                                        data-saveperformance="off" data-slotamount="7" data-title="Slide"
                                        data-transition="fade">
                                        <!-- MAIN IMAGE --><img alt="" class="rev-slidebg" data-bgfit="cover"
                                            data-bgparallax="off" data-bgposition="center top" data-bgrepeat="no-repeat"
                                            data-no-retina="" height="800"
                                            src="../../assets/images/Design and detail engg.jpeg" width="1200">
                                        <!-- LAYERS -->
                                        <!-- LAYER NR. 1 -->
                                        <div class="tp-caption constructionwhitebig tp-resizeme rs-parallaxlevel-0"
                                            data-height="['auto']" data-responsive_offset="on" data-splitin="none"
                                            data-splitout="none" data-start="500" data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-width="['auto']" data-x="110"
                                            data-y="163" id="slide-4-layer-1"
                                            style="z-index: 5; white-space: nowrap; font-size: 34px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Structural Audit </div>
                                        <!-- LAYER NR. 2 -->
                                        <div class="tp-caption constructionwhite tp-resizeme rs-parallaxlevel-0"
                                            data-end="8000" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="700"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="" data-width="['auto']"
                                            data-x="110" data-y="center" id="slide-4-layer-2"
                                            style="z-index: 6; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            Real world solutions<br>  Rewrite your history </div>
                                        <!-- LAYER NR. 3 -->
                                        <div class="tp-caption constructionbutton tp-resizeme rs-parallaxlevel-0"
                                            data-end="7700" data-height="['auto']" data-responsive_offset="on"
                                            data-splitin="none" data-splitout="none" data-start="900"
                                            data-transform_idle="o:1;"
                                            data-transform_in="opacity:0;s:700;e:Power3.easeInOut;"
                                            data-transform_out="auto:auto;s:700;" data-voffset="155"
                                            data-width="['auto']" data-x="110" data-y="bottom" id="slide-4-layer-3"
                                            style="z-index: 7; white-space: nowrap; font-size: 20px; line-height: 22px; font-weight: 400; color: rgba(255, 255, 255, 1.00);">
                                            <a [routerLink]="['/Structural-Auditing']">Read more</a> </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         <!-- About company -->
         <section class="section about-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="head-title text-center os-animation big" data-os-animation="fadeIn" data-os-animation-delay="0s"> ShreeSidhi EPC  Pvt.Ltd</h1>
                        <div class="divider-border divider-border-center element-top-10 element-bottom-10 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.1s" style="height:3px;">
                            <div class="divider-border-inner" style="width:60px;"></div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="figure element-top-40 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0.1s"> <span class="figure-image">
                                <img alt="devices-4.png"class="content-corner" src="../../assets/images/plan6.png">
                            </span> </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-content element-top-40 element-bottom-20 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0s">
                                    <p style="text-align:justify">Shreesidhi group offers engineering, project management consultancy and technical training services for various industries. We have good experienced team in detail engineering, project management for industries like power plant, refineries, fertilizes infrastructure.</p>
                                    <p style="text-align:justify">Currently we offer small and medium size projects for EPC and PMC companies. We have skilled group of engineers, designers and modelers.</p>
                                    <a [routerLink]="['/About-Us']" class="btn btn-primary btn-lg element-left-0 element-right-0 element-top-20 element-bottom-30 os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.2s" style="background: rgb(239 127 26 / 83%); animation-delay: 0.2s;">Learn more</a>
                                </div>        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        	<!-- Services section -->
			<section class="section  text-normal section-text-no-shadow section-inner-no-shadow section-normal section-opaque">
                <div class="list-container row element-top-0 element-bottom-50 os-animation"
                data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                <div class="col-md-12 text-normal element-top-0 element-bottom-20 text-center os-animation "
                    data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                    <div class="background-overlay grid-overlay-0 " style="background-color: rgba(250,250,250,1);"></div>
				<div class="container container-vertical-default">
					<div class="row vertical-default">
						<div class="col-md-12 text-default small-screen-default">
							<h1 class=" head-title text-center  element-top-50 element-bottom-0 text-normal os-animation big default animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0s" style="animation-delay: 0s;">
				                Services</h1>
							<div class="divider-border  divider-border-center element-top-30 element-bottom-60 os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.1s" style="height: 3px; animation-delay: 0.1s;">
								<div class="divider-border-inner" style="width:60px;"></div>
							</div>
							<div class=" element-top-0 element-bottom-50 recent-simple-os-container list-container row rounded">
								<div class="col-md-4 recent-simple-os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.3s" style="animation-delay: 0.3s;">
									<article id="post-46" class="content-corner post-grid post-grid-overlay element-bottom-20 text-left" style="background-image: url(../../assets/images/Designdetailengg..jpeg)">
										<a [routerLink]="['/Design-Details']">
											<div class="post-grid-content">
												<h3 class="post-grid-content-title">Design and Detail Engineering Services</h3>
												<div class="post-grid-content-footer"> read more </div>
											</div>
										</a>
									</article>
								</div>
								<div class="col-md-4 recent-simple-os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.6s" style="animation-delay: 0.6s;">
									<article id="post-57" class="content-corner post-grid post-grid-overlay element-bottom-20 text-left" style="background-image: url(../../assets/images/pmc2.jpg)">
										<a [routerLink]="['/PMC']">
											<div class="post-grid-content">
												<h3 class="post-grid-content-title">Project Management Consultancy(PMC)</h3>
												<div class="post-grid-content-footer"> read more </div>
											</div>
										</a>
									</article>
								</div>
								<div class="col-md-4 recent-simple-os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.9s" style="animation-delay: 0.9s;">
									<article id="post-58" class="content-corner post-grid post-grid-overlay element-bottom-20 text-left" style="background-image: url(../../assets/images/Constructionandmultyservices.jpg)">
										<a [routerLink]="['/Multi-Services']">
											<div class="post-grid-content">
												<h3 class="post-grid-content-title">Multi Services/Contracting-Civil/Mech/Electrical</h3>
												<div class="post-grid-content-footer"> read more </div>
											</div>
										</a>
									</article>
								</div>
                            </div>
                            <div class=" element-top-0 element-bottom-50 recent-simple-os-container list-container row">
								<div class="col-md-4 recent-simple-os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.3s" style="animation-delay: 0.3s;">
									<article id="post-46" class="content-corner post-grid post-grid-overlay element-bottom-20 text-left" style="background-image: url(../../assets/images/trading1.jpg)">
										<a [routerLink]="['/Trading']">
											<div class="post-grid-content">
												<h3 class="post-grid-content-title">Trading & Distribution</h3>
												<div class="post-grid-content-footer"> read more </div>
											</div>
										</a>
									</article>
								</div>
								<div class="col-md-4 recent-simple-os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.6s" style="animation-delay: 0.6s;">
									<article id="post-57" class="content-corner post-grid post-grid-overlay element-bottom-20 text-left" style="background-image: url(../../assets/images/training.jpg)">
										<a [routerLink]="['/Technical-Training']">
											<div class="post-grid-content">
												<h3 class="post-grid-content-title">Technical Traning</h3>
												<div class="post-grid-content-footer"> read more </div>
											</div>
										</a>
									</article>
								</div>
								<div class="col-md-4 recent-simple-os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.9s" style="animation-delay: 0.9s;">
									<article id="post-58" class="content-corner post-grid post-grid-overlay element-bottom-20 text-left" style="background-image: url(../../assets/images/structure.jpg)">
										<a [routerLink]="['/Structural-Auditing']">
											<div class="post-grid-content">
												<h3 class="post-grid-content-title">Structural Auditing</h3>
												<div class="post-grid-content-footer"> read more </div>
											</div>
										</a>
									</article>
								</div>
							</div>
						</div>
                    </div>     
				</div>
                    </div>
                </div> 
				
            </section>
           
            <!-- banner -->
            <section class="section">
                <div class="background-overlay banner-bg"></div>
                <div class="container container-vertical-middle">
                    <div class="row vertical-middle">
                        <div class="col-md-10">
                            <h2 class="head-title text-center element-top-60 element-bottom-60 text-light os-animation big light animated fadeInLeft" data-os-animation="fadeInLeft" data-os-animation-delay="0s"   style="animation-delay: 0s;"> Interested in our full business solutions? </h2>
                        </div>
                        <div class="col-md-2 text-center"> <a class="text-content btn btn-primary btn-lg text-light btn-icon-right element-top-60 element-bottom-60" data-os-animation="none" data-os-animation-delay="0s" [routerLink]="['/Contact-Us']">
                        Contact Us
                        <i class="fa fa-comment-o" data-animation="tada"></i>
                    </a> </div>
                    </div>
                </div>
            </section>
            <!-- slider -->
            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="head-title text-center os-animation big" data-os-animation="fadeIn" data-os-animation-delay="0s"> Projects</h1>
                            <div class="divider-border divider-border-center element-top-10 element-bottom-10 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.1s" style="height:3px;">
                                <div class="divider-border-inner" style="width:60px;"></div>
                            </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div class="list-container row element-top-0 element-bottom-50 os-animation"
                                    data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                                    <div class="col-md-12 text-normal element-top-0 element-bottom-20 text-center os-animation "
                                        data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                                        <div id="news-slider8" class="owl-carousel">
                                            <div class="post-slide8">
                                                <div class="post-img">
                                                    <img src="../../assets/images/pmc1.jpg" alt="">
                                                    <div class="over-layer">
                                                        <ul class="post-link">
                                                            <li><a href="#" class="fa fa-search"></a></li>
                                                            <li><a href="#" class="fa fa-link"></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                             
                                            <div class="post-slide8">
                                                <div class="post-img">
                                                    <img src="../../assets/images/structure.jpg" alt="">
                                                    <div class="over-layer">
                                                        <ul class="post-link">
                                                            <li><a href="#" class="fa fa-search"></a></li>
                                                            <li><a href="#" class="fa fa-link"></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="post-slide8">
                                                <div class="post-img">
                                                    <img src="../../assets/images/pmc1.jpg" alt="">
                                                    <div class="over-layer">
                                                        <ul class="post-link">
                                                            <li><a href="#" class="fa fa-search"></a></li>
                                                            <li><a href="#" class="fa fa-link"></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="post-slide8">
                                                <div class="post-img">
                                                    <img src="../../assets/images/training2.jpg" alt="">
                                                    <div class="over-layer">
                                                        <ul class="post-link">
                                                            <li><a href="#" class="fa fa-search"></a></li>
                                                            <li><a href="#" class="fa fa-link"></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="post-slide8">
                                                <div class="post-img">
                                                    <img src="../../assets/images/slider2.jpg" alt="">
                                                    <div class="over-layer">
                                                        <ul class="post-link">
                                                            <li><a href="#" class="fa fa-search"></a></li>
                                                            <li><a href="#" class="fa fa-link"></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="post-slide8">
                                                <div class="post-img">
                                                    <img src="../../assets/images/Constructionandmultyservices.jpg" alt="">
                                                    <div class="over-layer">
                                                        <ul class="post-link">
                                                            <li><a href="#" class="fa fa-search"></a></li>
                                                            <li><a href="#" class="fa fa-link"></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="post-slide8">
                                                <div class="post-img">
                                                    <img src="../../assets/images/pmc1.jpg" alt="">
                                                    <div class="over-layer">
                                                        <ul class="post-link">
                                                            <li><a href="#" class="fa fa-search"></a></li>
                                                            <li><a href="#" class="fa fa-link"></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>   
            </section>
               <section> <div class="QuestionsLook-here" id="Questions">
            <div class="website-design-question">
                <div class="row">
                    <div class="col-md-2 col-sm-2">
                    </div>
                    <div class="col-md-8 col-sm-8">
                        <div class="home-contact-info">
                            <img src="../assets/images/Logo.png" alt="Walstar" class="logo1">
                            <p class="question">Questions? Look here.</p>
                            <p class="question">Can’t find answer? call us at <a href="tel:+91 777 503 2331"><span class="contactno">+91 9763716548,8767098781</span></a></p>
                            <p class="question">or email us <a href="mailto:info@walstartechnologies.com"><span class="contactno">contact@shreesidhi.co.in</span></a></p>
                            
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2">
                    </div>
                </div>
            </div>
        </div>
    </section>
            <!--testinomials  -->
            <section class="section">
                <div class="background-media" style="background-image: url(../../assets/images/Designdetailengg..jpeg); background-attachment: fixed; background-size: cover; background-repeat: no-repeat;" data-start="background-position: 50% 0px" data-0-top-bottom="background-position: 50% -80px">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-2 text-center"></div>
                        <div class="col-md-8 text-center">
                            <div class="panel panel-default  element-top-100 element-bottom-100 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.4s">
                                <div class="panel-body" style="background-color:rgba(0, 0, 0, 0.69);">
                                    <h1 class="text-center  element-top-20 element-bottom-0 text-light os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.1s"> Guest Reviews </h1>
                                    <div class="figure element-top-10 element-bottom-20 os-animation" data-os-animation="fadeIn" data-os-animation-delay="0.2s"> <span class="figure-image">
                                    <img src="http://d169hzb81ub7u3.cloudfront.net/restaurant/wp-content/uploads/sites/14/2014/12/decor-2-big-white.png" alt="">
                                </span> </div>
                                    <div id="flexslider-93" class="element-top-20 element-bottom-20 text-center text-light flexslider feature-slider" data-os-animation="none" data-os-animation-delay="0s" data-flex-animation="slide" data-flex-controls-position="outside"
                                        data-flex-controlsalign="center" data-flex-directions="hide" data-flex-speed="7000" data-flex-controls="show" data-flex-slideshow="true">
                                        <ul class="slides">
                                           
                                            <li>
                                                <blockquote>
                                                    <p>Great support, approachable and knowledgeable team of developers..</p>
                                                    <footer> Ulhas</footer>
                                                </blockquote>
                                            </li>
                                            <li>
                                                <blockquote>
                                                    <p>I want to congratulate you on your new business venture. May your business grow and bring you prosperity. ..</p>
                                                    <footer>D.S.Baravade </footer>
                                                </blockquote>
                                            </li>
                                            <!-- <li>
                                                <blockquote>
                                                    <p></p>
                                                    <footer> </footer>
                                                </blockquote>
                                            </li>
                                            <li>
                                                <blockquote>
                                                    <p></p>
                                                    <footer> Farm House </footer>
                                                </blockquote>
                                            </li>
                                            <li>
                                                <blockquote>
                                                    <p>The ideal spot for any occasion, Lambda&nbsp;Restaurant serves dinner seven nights a week, and serves lunch Monday through Friday, when the room is awash in sunlight.</p>
                                                    <footer> Athens Square </footer>
                                                </blockquote>
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </section>
    </article>
</div>