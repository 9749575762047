import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesignDetailComponent } from './design-detail/design-detail.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PmcComponent } from './pmc/pmc.component';
import { CareerComponent } from './career/career.component';
import { NewsComponent } from './news/news.component';
import { MultiServicesComponent } from './multi-services/multi-services.component';
import { ProjectsComponent } from './projects/projects.component';
import { StructuralAuditingComponent } from './structural-auditing/structural-auditing.component';
import { TechnicalTrainingComponent } from './technical-training/technical-training.component';
import { TradingComponent } from './trading/trading.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
  { path: '', component: HomeComponent}, 
  { path: 'Design-Details', component: DesignDetailComponent},
  { path: 'About-Us', component: AboutUsComponent},
  { path: 'PMC', component: PmcComponent},
  { path: 'Career', component: CareerComponent},
  { path: 'News', component: NewsComponent},
  { path: 'Multi-Services', component: MultiServicesComponent},
  { path: 'Trading', component: TradingComponent},
  { path: 'Technical-Training', component: TechnicalTrainingComponent},
  { path: 'Projects', component: ProjectsComponent},
  { path: 'Structural-Auditing', component: StructuralAuditingComponent},
  { path: 'Contact-Us', component: ContactUsComponent},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
