<div id="content" role="main">
    <article>
        <section class="section pmc-content">
            <div class="background-media"> </div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn"
                        data-os-animation-delay="0s">
                        <h1 class="text-center  element-top-80 element-bottom-20 text-light" data-os-animation="none"
                            data-os-animation-delay="0s">Structural Auditing</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20"
                            data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="text-left head-title element-top-60 os-animation normal regular service-title" data-os-animation="fadeInRight"
                            data-os-animation-delay="0.1s">Structural Auditing</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation"
                            data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight"
                            data-os-animation-delay="0.3s">
                            <p class="text-content">Structural audit is an overall health and performance checkup of  a building like a doctor examines a patients.
                            </p>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft"
                            data-os-animation-delay="0s">
                            <img class="content-corner img-content" alt="p11" src="../../assets/images/structural.jpg">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="list-container row element-top-0 element-bottom-10 os-animation"
                        data-os-animation="fadeInUp" data-os-animation-delay="0.2s">

                        <div class="col-md-6 text-normal element-top-0 element-bottom-20 text-center os-animation "
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <img class="element-top-60 content-corner" alt="p11" src="../../assets/images/structure.jpg">
                        </div>

                        <div class="col-md-6 text-normal element-top-0 element-bottom-20 os-animation"
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <h2 class="element-top-60  head-title sub-title">Structural Auditing Services</h2>
                            <div class="divider-border divider-border-left element-top-10 element-bottom-10">
                                <div class="divider-border-inner"></div>
                            </div>
                            <div>
                                <ul class="web-design-list text-content">
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Structural audit is an overall health and performance checkup of  a building like a doctor examines a patients.</li>
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>It ensures that the building and its premises are safe and have no risk. It analyses and suggests appropriate repairs and retrofitting measures required for the buildings to perform  better in its service.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</div>