<div id="content" role="main">
    <article>
        <section class="section pmc-content">
            <div class="background-media"> </div>
            <div class="background-overlay grid-overlay-0"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 text-center small-screen-center os-animation" data-os-animation="fadeIn" data-os-animation-delay="0s">
                        <h1 class="text-center element-top-80 element-bottom-20 text-light" data-os-animation="none" data-os-animation-delay="0s">Technical Training & Manpower Services</h1>
                        <div class="divider-border divider-border-center element-top-20 element-bottom-20 " data-os-animation="none" data-os-animation-delay="0s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <!-- <div class="col-text-1 text-light element-top-0 element-bottom-80" data-os-animation="none" data-os-animation-delay="0s">
                            <p style="text-align: center;"><strong>Lambda</strong> is an international highly diversified company focused on Infrastructure, Building, Industrial, MEP, Solar Power Construction, EPC and Concession Contracts, Facility and Project Management. Our vision
                                is to enhance further our role as a worldwide construction group by securing sustainable future development through continuous improvement of services based on our values such as integrity, teamwork, responsibility
                                and transparency.</p>
                        </div> -->
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </div>
        </section>
        <!--pmc-summary-section  -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="text-left  head-title element-top-60 os-animation normal regular service-title" data-os-animation="fadeInRight" data-os-animation-delay="0.1s">Technical Training and Manpower Services</h2>
                        <div class="divider-border divider-border-left element-top-10 element-bottom-10 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.2s">
                            <div class="divider-border-inner"></div>
                        </div>
                        <div class="col-text-1 os-animation" data-os-animation="fadeInRight" data-os-animation-delay="0.3s">
                            <p class="text-contect">We provide expertise for identifying and organizing suitable learning solutions for developing basic technical management competencies. We also provide manpower services for various technical profiles.</p>
                        </div>
                   
                    </div>
                    <div class="col-md-6">
                        <div class="figure element-top-60 element-bottom-20 os-animation" data-os-animation="fadeInLeft" data-os-animation-delay="0s"> 
                        <img alt="p11" src="../../assets/images/technical-training.jpg">
                    </div>
                    </div>
                </div>
                </div>
        </section>

        <!-- Services -->
        <section class="section">
            <div class="container">
                <div class="row">
                    <div class="list-container row element-top-0 element-bottom-10 os-animation"
                        data-os-animation="fadeInUp" data-os-animation-delay="0.2s">

                        <div class="col-md-6 text-normal element-top-0 element-bottom-20 text-center os-animation "
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <img class="element-top-10" alt="p11" src="../../assets/images/Constructionandmultyservices.jpg">
                        </div>

                        <div class="col-md-6 text-normal element-top-0 element-bottom-20 os-animation"
                            data-os-animation="fadeInUp" data-os-animation-delay="0.2s">
                            <h2 class="sub-title head-title" >Technical Training Services</h2>
                            <div class="divider-border divider-border-left element-top-10 element-bottom-10">
                                <div class="divider-border-inner"></div>
                            </div>
                            <div>
                                <ul class="web-design-list text-content">
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Project management</li>
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Staad pro/ Tekla/ Autocad</li>
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>MS office/ MS excel</li>
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Piping engg</li>
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Comunication skills</li>
                                    <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Personality developement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</div>
        